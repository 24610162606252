import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "message", "messageClass"]
  tresholdMaxNumberOfSamples = 300

  initialize() {
    this.calculate()
  }
  
  calculate() {
    var sum = 0
    this.checkboxTargets.forEach((element, index) => {
      if (element.checked)
        sum += Number(element.dataset.numberOfSamples)
    })
    
    if (sum <= this.tresholdMaxNumberOfSamples) {
      this.messageTarget.innerHTML = `Du har valgt <b>${sum}</b> jordprøver`
      this.messageTarget.className = 'alert alert-info'
    }
    else {
      this.messageTarget.innerHTML = `Du har valgt <b>${sum}</b> jordprøver. Dette er mer enn maks antall samtidige prøver som får plass i analyseinstrumentene.`
      this.messageTarget.className = 'alert alert-danger'
    }
  }

  toggleAll(event) {
    event.preventDefault()
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })
    this.calculate()
  }

}
