import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display"]
  static values = {
    analysis: String
  }

  connect() {
    console.log("Hello World from results ctrl!")
    this.showhide("")
  }

  change(event) {
    let analysisType = event.target.value

    console.log("Changed analysis type to " + analysisType)

    this.showhide(analysisType)    
  }

  showhide(analysisType) {
    console.log("run checks")

    this.displayTargets.forEach((element, index) => {
      let shouldHideElement = element.dataset.analysis != analysisType
      console.log("checking element: ", element.dataset.analysis, shouldHideElement);
      element.hidden = shouldHideElement
    })
  }

}
